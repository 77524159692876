<template>
  <div class="menu">
    <ul>
      <li @click="goto(chapters[0].firstPage)">État des lieux</li>
      <li @click="goto(chapters[1].firstPage)">Législation</li>
      <li @click="goto(chapters[2].firstPage)">Conduites à risques</li>
      <li @click="goto(chapters[3].firstPage)">Équipements</li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "MenuHome",

  methods: {
    goto: function (page) {
      this.$emit("close-side");
      var pageURL = "/page/" + page;
      this.$router.push({ path: pageURL });
    },
  },
};
</script>

<style lang="scss">
$bleu: #034ea2;
$Orange: #f68c59;

.menu {
  background: $bleu;
  color: #ffffff;

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
  }

  li {
    float: left;
    font-size: 3vh;
    font-weight: bold;
    cursor: pointer;
    
      display: block;
      color: white;
      text-align: center;
      padding: 5vh;
      text-decoration: none;
    
  }
}
</style>