<template>
  <div class="home">
    <div class="title">
      <img src="../assets/home.jpg">
      <h2>2 roues motorisés</h2>
      <h1>La sécurité <br>tous les jours</h1>
    </div>   
    <div class="nav">
      <LogoHome></LogoHome>
      <MenuHome></MenuHome>
    </div>
  </div>
</template>

<script>
import MenuHome from "@/components/MenuHome.vue";
import LogoHome from "@/components/LogoHome.vue";

export default {
  name:"Home",

  components: {
    MenuHome,
    LogoHome,
  },

}
</script>

<style lang="scss" scoped>

.home {
  .nav {
    bottom: 0px;
    height: 8vw;
    position: absolute;
    display: flex;
    width: 100vw;

    .logo {
      float: left;
      flex-grow: 1;
    }
    .menu {
      flex-grow: 1;
      flex-basis: auto;
    }
  }
}

.title {

  img {
    object-fit: cover;
    width:100%;
    height: 86vh;
    z-index: 1;
    position: absolute;
    left: 0px;
    top: 0px;
  }

  h1 {
    z-index: 2;
    position: absolute;
    left: 5vw;
    top: 57vh;
    font-size: 5em;
    text-transform: uppercase;
    text-align:left;
    line-height: 0.9em;
  }

  h2 {
    z-index: 2;
    position: absolute;
    left: 5vw;
    top: 52vh;
    font-size: 2.3em;
    text-transform: uppercase;
  }
}
</style>
