<template>
  <div class="main">
    <router-view></router-view>
  </div>
</template>


<style lang="scss">

$bleu:#034EA2;
$Orange:#F68C59;

@font-face {
  font-family: "Konnect";
  font-style: normal;
  font-weight: normal;
  src: url("/font/Konnect-Regular.woff") format("woff");
}

@font-face {
  font-family: "Konnect";
  font-style: normal;
  font-weight: bold;
  src: url("/font/Konnect-Bold.woff") format("woff");
}

@font-face {
  font-family: "Konnect";
  font-style: normal;
  font-weight: 500;
  src: url("/font/Konnect-Medium.woff") format("woff");
}

@font-face {
  font-family: "Konnect";
  font-style: normal;
  font-weight: 600;
  src: url("/font/Konnect-SemiBold.woff") format("woff");
}

#app {
  font-family: Konnect;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: $bleu;
}

body {
  margin:0;
  padding: 0;
  overflow: hidden;
  font-size: 17px;
}

.main {
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
}

</style>