<template>
    <div class="logo">
        <img src="@/assets/logo_home.svg">
    </div>
</template>

<script>
export default {
    name:"LogoHome",
}
</script>

<style lang="scss" scoped>
img {
    height: 8vw;
}
</style>