import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

const app = createApp(App);

app.config.globalProperties.appState = [
    {
        activePage: 1,
        activeChapter: 1,
        previousPage: "home",
        nextPage: "2"
    },
    {
        activePage: 2,
        activeChapter: 1,
        previousPage: "1",
        nextPage: "3"
    },
    {
        activePage: 3,
        activeChapter: 1,
        previousPage: "2",
        nextPage: "4"
    },    
    {
        activePage: 4,
        activeChapter: 1,
        previousPage: "3",
        nextPage: "5"
    },
    {
        activePage: 5,
        activeChapter: 2,
        previousPage: "4",
        nextPage: "6"
    },
    {
        activePage: 6,
        activeChapter: 2,
        previousPage: "5",
        nextPage: "7"
    },
    {
        activePage: 7,
        activeChapter: 2,
        previousPage: "6",
        nextPage: "8"
    },
    {
        activePage: 8,
        activeChapter: 2,
        previousPage: "7",
        nextPage: "9"
    },
    {
        activePage: 9,
        activeChapter: 2,
        previousPage: "8",
        nextPage: "10"
    },
    {
        activePage: 10,
        activeChapter: 2,
        previousPage: "9",
        nextPage: "11"
    },
    {
        activePage: 11,
        activeChapter: 2,
        previousPage: "10",
        nextPage: "12"
    },
    {
        activePage: 12,
        activeChapter: 2,
        previousPage: "11",
        nextPage: "13"
    },
    {
        activePage: 13,
        activeChapter: 2,
        previousPage: "12",
        nextPage: "14"
    },
    {
        activePage: 14,
        activeChapter: 2,
        previousPage: "13",
        nextPage: "15"
    },
    {
        activePage: 15,
        activeChapter: 2,
        previousPage: "14",
        nextPage: "16"
    },
    {
        activePage: 16,
        activeChapter: 2,
        previousPage: "15",
        nextPage: "17"
    },
    {
        activePage: 17,
        activeChapter: 2,
        previousPage: "16",
        nextPage: "18"
    },
    {
        activePage: 18,
        activeChapter: 2,
        previousPage: "17",
        nextPage: "19"
    },
    {
        activePage: 19,
        activeChapter: 2,
        previousPage: "18",
        nextPage: "20"
    },
    {
        activePage: 20,
        activeChapter: 2,
        previousPage: "19",
        nextPage: "21"
    },
    {
        activePage: 21,
        activeChapter: 2,
        previousPage: "20",
        nextPage: "22"
    },
    {
        activePage: 22,
        activeChapter: 2,
        previousPage: "21",
        nextPage: "23"
    },
    {
        activePage: 23,
        activeChapter: 2,
        previousPage: "22",
        nextPage: "24"
    },
    {
        activePage: 24,
        activeChapter: 2,
        previousPage: "23",
        nextPage: "25"
    },
    {
        activePage: 25,
        activeChapter: 2,
        previousPage: "24",
        nextPage: "26"
    },
    {
        activePage: 26,
        activeChapter: 2,
        previousPage: "25",
        nextPage: "27"
    },
    {
        activePage: 27,
        activeChapter: 2,
        previousPage: "26",
        nextPage: "28"
    },
    {
        activePage: 28,
        activeChapter: 2,
        previousPage: "27",
        nextPage: "29"
    },
    {
        activePage: 29,
        activeChapter: 2,
        previousPage: "28",
        nextPage: "30"
    },
    {
        activePage: 30,
        activeChapter: 3,
        previousPage: "29",
        nextPage: "31"
    },
    {
        activePage: 31,
        activeChapter: 3,
        previousPage: "30",
        nextPage: "32"
    },
    {
        activePage: 32,
        activeChapter: 3,
        previousPage: "31",
        nextPage: "33"
    },
    {
        activePage: 33,
        activeChapter: 3,
        previousPage: "32",
        nextPage: "34"
    },
    {
        activePage: 34,
        activeChapter: 3,
        previousPage: "33",
        nextPage: "35"
    },
    {
        activePage: 35,
        activeChapter: 3,
        previousPage: "34",
        nextPage: "36"
    },   
    {
        activePage: 36,
        activeChapter: 3,
        previousPage: "35",
        nextPage: "37"
    },
    {
        activePage: 37,
        activeChapter: 3,
        previousPage: "36",
        nextPage: "38"
    },
    {
        activePage: 38,
        activeChapter: 3,
        previousPage: "37",
        nextPage: "46"
    },
    {
        activePage: 39,
        activeChapter: 3,
        previousPage: "38",
        nextPage: "40"
    },
    {
        activePage: 40,
        activeChapter: 3,
        previousPage: "39",
        nextPage: "41"
    },
    {
        activePage: 41,
        activeChapter: 3,
        previousPage: "40",
        nextPage: "42"
    },
    {
        activePage: 42,
        activeChapter: 3,
        previousPage: "41",
        nextPage: "43"
    },
    {
        activePage: 43,
        activeChapter: 3,
        previousPage: "42",
        nextPage: "44"
    },
    {
        activePage: 44,
        activeChapter: 3,
        previousPage: "43",
        nextPage: "45"
    },
    {
        activePage: 45,
        activeChapter: 3,
        previousPage: "44",
        nextPage: "46"
    },
    {
        activePage: 46,
        activeChapter: 3,
        previousPage: "38",
        nextPage: "47"
    },
    {
        activePage: 47,
        activeChapter: 3,
        previousPage: "46",
        nextPage: "48"
    },
    {
        activePage: 48,
        activeChapter: 3,
        previousPage: "47",
        nextPage: "49"
    },
    {
        activePage: 49,
        activeChapter: 3,
        previousPage: "48",
        nextPage: "50"
    },
    {
        activePage: 50,
        activeChapter: 3,
        previousPage: "49",
        nextPage: "51"
    },
    {
        activePage: 51,
        activeChapter: 3,
        previousPage: "50",
        nextPage: "52"
    },
    {
        activePage: 52,
        activeChapter: 3,
        previousPage: "51",
        nextPage: "53"
    },
    {
        activePage: 53,
        activeChapter: 3,
        previousPage: "52",
        nextPage: "54"
    },
    {
        activePage: 54,
        activeChapter: 3,
        previousPage: "53",
        nextPage: "55"
    },
    {
        activePage: 55,
        activeChapter: 3,
        previousPage: "54",
        nextPage: "56"
    },
    {
        activePage: 56,
        activeChapter: 3,
        previousPage: "55",
        nextPage: "57"
    },
    {
        activePage: 57,
        activeChapter: 4,
        previousPage: "56",
        nextPage: "58"
    },
    {
        activePage: 58,
        activeChapter: 4,
        previousPage: "57",
        nextPage: "59"
    },
    {
        activePage: 59,
        activeChapter: 4,
        previousPage: "58",
        nextPage: "60"
    },
    {
        activePage: 60,
        activeChapter: 4,
        previousPage: "59",
        nextPage: "61"
    },
    {
        activePage: 61,
        activeChapter: 4,
        previousPage: "60",
        nextPage: "62"
    },
    {
        activePage: 62,
        activeChapter: 4,
        previousPage: "61",
        nextPage: "63"
    },
    {
        activePage: 63,
        activeChapter: 4,
        previousPage: "62",
        nextPage: "64"
    },
    {
        activePage: 64,
        activeChapter: 4,
        previousPage: "63",
        nextPage: "65"
    },
    {
        activePage: 65,
        activeChapter: 4,
        previousPage: "64",
        nextPage: "end"
    }
];

app.config.globalProperties.chapters = [
    {
        firstPage: 1,
        links: [
            { title: 'Vidéo de présentation', page: 1 },
            { title: 'L’accidentalité 2RM', page: 2 },
            { title: 'Focus : Accidents mortels 2RM', page: 3 },
        ]
    },
    {
        firstPage: 5,
        links: [
            { title: "Code de la route", page: 5 },
            { title: 'Alcool et stupéfiants', page: 9 },
            { title: 'Vitesse', page: 19},
            { title: 'Hypovigilance', page: 25 },
        ]
    },
    {
        firstPage: 30,
        links: [
            { title: "Voies rapides et autoroutes", page: 30 },
            { title: 'Milieu urbain', page: 35 },
            { title: 'Règles élémentaires de sécurité', page: 46 },
            { title: 'Environnement', page: 53 },
        ]
    },
    {
        firstPage: 57,
        links: [
            { title: "Équipements obligatoires", page: 57 },
            { title: 'Équipements conseillés', page: 62 },
        ]
    }
];

//createApp(App).mount('#app')
app.use(router).mount('#app')
//app.mount('#app')
